import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Ticker from "react-ticker"
import SendToPageCTA from "components/send-to-page-cta"
import Diptyque from "components/diptyque"
import IndexList from "components/index-list"
import MediaWithCaption from "components/media-with-caption"
import LazyLoad from "react-lazyload"
import InlineVideo from "components/inline-video"
import constants from "../../constants"
import VideoPlayer from "../about-videoPlayer/about-videoPlayer"

// import Img from "gatsby-image"
// import RoundedBtn from "components/rounded-btn"

const Core = () => {
  const indexItems = [
    [
      `1. ECCO Texture Pot Bag in Granat`,
      `2. ECCO Textureblock Pot Bag in Aubergine, ECCO Textureblock Key Pouch in Black`,
      `3. ECCO Textureblock Pot Bag in Aubergine, ECCO Textureblock Key Pouch in Black`,
      `4. ECCO Contact Drawstring Bag in Aubergine`,
      `5. ECCO Textureblock Saddle Bag in Rosé`
    ],
    [
      `6. ECCO Textureblock Pot Bag in Granat`,
      `7. ECCO Textureblock Flat Pouch in Granat, ECCO Textureblock Camera Bag in Dark Forest Green`,
      `8. ECCO Textureblock Midi Pouch in Dark Forest Green, ECCO Textureblock Pot Bag in Black`,
      `9. ECCO Textureblock Full Pack Electric Blue`
    ],
    [
      `10. ECCO Textureblock Saddle Bag in Black, ECCO Textureblock Pot Bag in Black, ECCO Textureblock Pod Case in Dark Forest Green`,
      `11. ECCO Textureblock Bottle Bag in Black`,
      `12. ECCO Textureblock Bottle Bag in Black, ECCO Textureblock Pot Bag in Black, ECCO Textureblock Pod Case in Dark Forest Green`
    ]
  ]
  const data = useStaticQuery(graphql`
    fragment fluidImage on File { childImageSharp { fluid(maxWidth: 1980, quality:80, ) { ...GatsbyImageSharpFluid_withWebp_noBase64 } } }
    query {
      diptyque: file(relativePath: { eq: "images/core/Sofia - Baseline - Universal - crop.jpg" }) { ...fluidImage }
      thumb: file(relativePath: { eq: "images/core/video_thumb.jpg" }) { ...fluidImage }
      cristiani: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_130_STILL LIFE_734 1.jpg" }) { ...fluidImage }
      cristiani2: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_140_769 1.jpg" }) { ...fluidImage }
      cristiani3: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_140_819 1.jpg" }) { ...fluidImage }
      cristiani4: file(relativePath: { eq: "images/core/Screenshot 2021-01-21 at 15.57 1.jpg" }) { ...fluidImage }
      cristiani5: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_170_1006 5.jpg" }) { ...fluidImage }
      cristiani6: file(relativePath: { eq: "images/core/Yellow pattern 1.jpg" }) { ...fluidImage }
      cristiani7: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_130_STILL LIFE_002 2.jpg" }) { ...fluidImage }
      cristiani8: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_160_944 2.jpg" }) { ...fluidImage }
      cristiani9: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_130_STILL LIFE_011.jpg" }) { ...fluidImage }
      cristiani10: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_180_1042.jpg" }) { ...fluidImage }
      cristiani11: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_200_STILL LIFE_1195 1.jpg" }) { ...fluidImage }
      cristiani12: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_190_1158 4.jpg" }) { ...fluidImage }
      cristiani13: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_190_1109 1.jpg" }) { ...fluidImage }
    }
  `)
  const overallPadding = constants.landingOverallPadding
  const textColor = `text-black`
  const Grid = ({ children, className }) => (
    <div className={`grid grid-cols-12 ${className}`}>{children}</div>
  )
  return (
    <div className={`w-full bg-white`}>
      <div className={`bg-primary`}>
        <Ticker
          speed={10}
        >
          {() => (
            <p className={`bg-primary text-black flex items-center pt-2 pb-2 lg:pt-2 lg:pb-4 ${constants.h2} whitespace-nowrap`}>
              &nbsp;&#8226;&nbsp;Meet the Artists, Engineers, Designers and Scientists Who Know What Tomorrow Looks Like
            </p>
          )}
        </Ticker>
      </div>
      <p className={`${textColor} ${constants.quote} ${overallPadding} px-7 pr-10 pt-16 pb-6 lg:pt-20 lg:pb-10`}>
      The Core Collection, together with the Brand Collection, forms our main line. Key styles such as the Saddle, Bottle, Camera and Shopper Bags, and a new generation of small leather goods, offer consumers a more hands-free approach to wearing and living.
      </p>
      <Grid className={`${overallPadding} mt-10 lg:mt-10`}>
        <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-6 mb-16 lg:mb-0`}>
          <VideoPlayer
            url="210125_GYOC_1.mp4"
            thumbnail={data.thumb}
            className=""
          />
          <p className={`pl-2 lg:pl-0 ${constants.caption} pt-2`}>
            Meet the designer and discover the experiment.
          </p>
        </div>
        <div className={`col-start-2 col-end-11 lg:col-start-6 lg:col-end-13 mb-5 lg:mb-0`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
            <div className={`lg:mx-auto w-full lg:w-7/12 ${overallPadding}`}>
              <p className={`${textColor} ${constants.paragraph} pb-4`}>Inspired by Biotechnologists</p>
              <p className={`${textColor} ${constants.paragraph}`}>
                Together with designers and biotechnologists Grow Your Own Cloud we created a design brief for an experiment that helps you cleanse your data, and reduce the impact of data warming on our planet. We then asked people from our network to conduct the experiment, using ECCO Leather Goods as their essential equipment, or work goods. Our lookbook images capture the spirit of designers in progress.
              </p>
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-1 col-end-12 lg:col-start-1 lg:col-end-10`}
          captionClassName={`${textColor} pl-2 lg:pl-0`}
          image={data.cristiani}
        >
          Image 1
        </MediaWithCaption>
        <div className={`col-start-2 col-end-13 lg:col-start-10 lg:col-end-13 mb-5 lg:mb-0`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
            <div className={`lg:mx-auto w-9/12 lg:w-8/12 ${overallPadding}`}>
              <p className={`mt-7 lg:mt-8 ${textColor} ${constants.paragraph}`}>
                During your 30 day challenge, plant a seed and watch it flourish as your reduce your data.
              </p>
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={`mt-4 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-2 col-end-13 lg:col-start-1 lg:col-end-8`}
          captionClassName={`${textColor}`}
          image={data.cristiani2}
        >
          Image 2
        </MediaWithCaption>
        <div className={`col-start-2 col-end-13 lg:col-start-8 lg:col-end-13 mb-5 lg:mb-0`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
            <div className={`lg:mx-auto w-9/12 lg:w-8/12 ${overallPadding}`}>
              <p className={`mt-8 ${textColor} ${constants.paragraph}`}>
                From phone and keys, to specialist equipment, small leather goods keep everything within reach
              </p>
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={`mt-4 lg:mt-16`}>
        <div className={`${overallPadding} col-start-2 col-end-12 lg:col-start-1 lg:col-end-6`}>
          <LazyLoad>
            <p className={`hidden lg:block lg:pt-20 lg:pb-20 ${textColor} ${constants.paragraph} w-10/12`}>
              Segolene combines the Pot Bag and Key Pouch together with the handy D-ring.
            </p>
            <InlineVideo
              src="220121_CONVOY_ECCO_PLANASEED_ETALO_V1_H264.mp4"
              videoStyle={{objectFit: 'cover'}}
            />
            <p className={`lg:hidden pt-7 lg:pt-20 lg:pb-20 ${textColor} ${constants.paragraph} w-10/12`}>
              Segolene combines the Pot Bag and Key Pouch together with the handy D-ring.
            </p>
          </LazyLoad>
        </div>
        <div className={`col-start-1 col-end-12 lg:col-start-8 lg:col-end-13 mb-7 lg:mb-0 mt-7 lg:mt-0`}>
          <MediaWithCaption
            className={`w-full`}
            captionClassName={`pl-2 lg:pl-0 ${textColor}`}
            image={data.cristiani3}
          >
            Image 3
          </MediaWithCaption>
        </div>
      </Grid>
      <Grid className={`mt-4 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-1 col-end-12 lg:col-start-5 lg:col-end-10`}
          captionClassName={`${textColor}`}
          image={data.cristiani4}
        >
          Image 4
        </MediaWithCaption>
      </Grid>
      <Grid className={`mt-4 lg:mt-32`}>
        <div className={`col-start-2 col-end-13 lg:col-start-1 lg:col-end-6 pb-7 lg:pb-0`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
            <div className={`lg:mx-auto w-9/12 lg:w-8/12 ${overallPadding}`}>
              <p className={`lg:mt-8 ${textColor} ${constants.paragraph}`}>
                Stream in: reduce your data by listening to the sounds around you, rather than music on your phone. Then visualize what you hear.
              </p>
            </div>
          </div>
        </div>
        <MediaWithCaption
          className={`${overallPadding} col-start-2 col-end-13 lg:col-start-6 lg:col-end-12 mb-5 lg:mb-0`}
          captionClassName={`pl-2 lg:pl-0 ${textColor}`}
          image={data.cristiani5}
        >
          Image 5
        </MediaWithCaption>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <MediaWithCaption
          className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-13`}
          captionClassName={`${textColor}`}
          image={data.cristiani6}
        />
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-2 col-end-12 lg:col-start-7 lg:col-end-13`}
          captionClassName={`${textColor}`}
          image={data.cristiani7}
        >
          Image 6
        </MediaWithCaption>
      </Grid>
      <div className={`mt-10 lg:mt-96 bg-yellow-light`}>
        <Grid className={``}>
          <MediaWithCaption
            className={`${overallPadding} col-start-1 col-end-12 lg:col-start-1 lg:col-end-8 lg:relative lg:-top-32 pt-7 lg:pt-0`}
            captionClassName={`pl-2 lg:pl-0 ${textColor}`}
            image={data.cristiani8}
          >
            Image 8
          </MediaWithCaption>
          <MediaWithCaption
            className={`${overallPadding} col-start-2 col-end-12 lg:col-start-9 lg:col-end-13 lg:relative lg:-top-72 pt-7 lg:pt-0`}
            captionClassName={`${textColor}`}
            image={data.cristiani9}
          >
            Image 7
          </MediaWithCaption>
        </Grid>
        <Grid className={`mt-7 lg:mt-0`}>
          <div className={`col-start-2 col-end-11 lg:col-start-1 lg:col-end-6 mb-7 lg:mb-0`}>
            <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
              <div className={`lg:mx-auto w-full lg:w-8/12 ${overallPadding}`}>
                <p className={`${textColor} ${constants.paragraph}`}>
                  Biliel streams in wearing a collection of small leather goods.
                </p>
              </div>
            </div>
          </div>
          <div className={`col-start-1 col-end-12 lg:col-start-6 lg:col-end-10 mb-7 lg:mb-0`}>
            <LazyLoad>
              <InlineVideo
                src="220121_CONVOY_ECCO_STREAMINA_ETALO_V1_H264.mp4"
                videoStyle={{objectFit: 'cover'}}
              />
            </LazyLoad>
          </div>
        </Grid>
        <Grid className={`mt-4 lg:mt-0 lg:relative lg:top-32 pb-7 lg:pb-0`}>
          <MediaWithCaption
            className={`${overallPadding} col-start-2 col-end-13 lg:col-start-3 lg:col-end-8`}
            captionClassName={`${textColor}`}
            image={data.cristiani10}
          >
            Image 9
          </MediaWithCaption>
        </Grid>
      </div>
      <Grid className={`mt-10 lg:mt-48`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-1 col-end-12 lg:col-start-1 lg:col-end-7 mt-0 lg:mt-48`}
          captionClassName={`pl-2 lg:pl-0 ${textColor}`}
          image={data.cristiani11}
        >
          Image 11
        </MediaWithCaption>
        <div className={`col-start-2 col-end-13 lg:col-start-8 lg:col-end-13 mb-5 lg:mb-0`}>
          <p className={`lg:hidden ${textColor} ${constants.paragraph} w-9/12 mt-7 mb-7 lg:mt-12 lg:mb-4`}>
            Smooth and pebbled Textureblock Leather creates an iconic Bottle Bag for life on the go.
          </p>
          <div className={`lg:flex lg:flex-col lg:justify-top lg:h-full relative`}>
            <div className={`lg:mx-auto w-full`}>
              <MediaWithCaption
                className={`lg:pr-7`}
                captionClassName={`${textColor}`}
                image={data.cristiani12}
              >
                Image 10
              </MediaWithCaption>
            </div>
            <p className={`hidden lg:inline ${textColor} ${constants.paragraph} absolute bottom-20 w-7/12`}>
              Smooth and pebbled Textureblock Leather creates an iconic Bottle Bag for life on the go.
            </p>
          </div>
        </div>
      </Grid>
      <Grid className={`mt-4 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-1 col-end-13 lg:col-start-4 lg:col-end-10`}
          captionClassName={`pl-2 lg:pl-0 ${textColor}`}
          image={data.cristiani13}
        >
          Image 12
        </MediaWithCaption>
      </Grid>
      <IndexList
        className={`${textColor} ${overallPadding} ${constants.attribution} mt-10 lg:mt-24`}
        items={indexItems}
      />
      <Diptyque
        props={{
          title: `Enter a Brave New World`,
          paragraph: `Sofia Fanego is an artist, permaculturist and model. She is featured in the AW/21 campaign images for our Core Collection wearing the ECCO Textureblock Saddle Bag and Bottle Bag.`,
          color: `#BC9CDB`,
          borderColor: `border-black`,
          textColor: `text-black`,
          image: data.diptyque,
          button: {
            to: `/catalogue?collection=core`,
            text: `Discover the AW/21 campaign`
          }
        }}
      />
      <SendToPageCTA
        title={`Discover the Core Collection`}
        button={{
          to: `/catalogue`,
          title: `View All Products`
        }}
      />
    </div>
  )
}

export default Core
