import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Ticker from "react-ticker"
import SendToPageCTA from "components/send-to-page-cta"
import Diptyque from "components/diptyque"
import IndexList from "components/index-list"
import MediaWithCaption from "components/media-with-caption"
import LazyLoad from "react-lazyload"
import InlineVideo from "components/inline-video"
import Img from "gatsby-image"
import constants from "../../constants"
import VideoPlayer from "../about-videoPlayer/about-videoPlayer"
// import Img from "gatsby-image"
// import RoundedBtn from "components/rounded-btn"

const Brand = () => {
  const indexItems = [
    [
      `1. ECCO Hybrid Pot Bag in Marigold, ECCO Journey Pillow Pack in Dark Green Forest`,
      `2. ECCO Pillow Phone Bag in Marigold, ECCO Pillow Phone Bag in Aubergine/Lilac Marble`,
      `3. ECCO Pillow Drawstring Pack in Marigold, ECCO Pillow Drawstring Pack in Aubergine/Lilac Marble`,
      `4. ECCO Journey Midi Pouch in Aubergine Embossed. ECCO Journey Midi Pouch in Electric Blue Embossed, ECCO Journey Key Pouch in Lilac Marble Embossed`
    ],
    [
      `5. ECCO Journey Midi Pouch in Aubergine Embossed. ECCO Journey Midi Pouch in Electric Blue Embossed, ECCO Journey Key Pouch in Lilac Marble Embossed`,
      `6. ECCO Textureblock Midi Pouch in ECCO Blue, ECCO Journey Midi Pouch in Green Pine/Vetiver`,
      `7. ECCO Journey Crossbody in Electric Blue`,
      `8. ECCO Journey Camera Bag in Dark Green Forest`
    ],
    [
      `9. ECCO City Laptop Bag in Wet Almond, ECCO Journey Passport Holder in ECCO Blue`,
      `10. ECCO Journey Camera Bag in Castle Rock`,
      `11. ECCO Journey Card Case in Black, ECCO E Padded Charm, ECCO Hybrid Pot Bag in Black`
    ]
  ]
  const data = useStaticQuery(graphql`
    fragment fluidImage on File { childImageSharp { fluid(maxWidth: 1980, quality:80, ) { ...GatsbyImageSharpFluid_withWebp_noBase64 } } }
    query {
      diptyque: file(relativePath: { eq: "images/brand/Matthias - Baseline - Universal.jpg" }) { ...fluidImage }
      thumb: file(relativePath: { eq: "images/brand/video_thumb.jpg" }) { ...fluidImage }
      cristiani: file(relativePath: { eq: "images/core/ECCO_210114_TCRISTIANI_130_STILL LIFE_734 1.jpg" }) { ...fluidImage }
      cristiani2: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_310_STILL LIFE_1790_V1.jpg" }) { ...fluidImage }
      cristiani3: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_230_DUO_1398_V1.jpg" }) { ...fluidImage }
      cristiani4: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_220_1293_V1.jpg" }) { ...fluidImage }
      cristiani5: file(relativePath: { eq: "images/brand/201201_ECCO Leather Goods Video Graphics MB49 1.jpg" }) { ...fluidImage }
      cristiani6: file(relativePath: { eq: "images/brand/201201_ECCO Leather Goods Video Graphics MB49 1.jpg" }) { ...fluidImage }
      cristiani7: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_240_1427_V1B.jpg" }) { ...fluidImage }
      cristiani8: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_240_1440_V1B.jpg" }) { ...fluidImage }
      cristiani9: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_250_STILL LIFE_1445_V1B.jpg" }) { ...fluidImage }
      cristiani10: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_260_1535_V1.jpg" }) { ...fluidImage }
      cristiani11: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_260_1493_V1.jpg" }) { ...fluidImage }
      cristiani12: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_270_1566_V1.jpg" }) { ...fluidImage }
      cristiani13: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_280_1637_V1.jpg" }) { ...fluidImage }
      cristiani14: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_280_1600_V1.jpg" }) { ...fluidImage }
      cristiani15: file(relativePath: { eq: "images/brand/ECCO_210114_TCRISTIANI_300_1764_V1.jpg" }) { ...fluidImage }
    }
  `)
  const overallPadding = constants.landingOverallPadding
  const textColor = `text-black`
  const Grid = ({ children, className }) => (
    <div className={`grid grid-cols-12 ${className}`}>{children}</div>
  )
  return (
    <div className={`w-full bg-white`}>
      <div className={`bg-primary`}>
        <Ticker
          speed={10}
        >
          {() => (
            <p className={`bg-primary text-black flex items-center pt-2 pb-2 lg:pt-2 lg:pb-4 ${constants.h2} whitespace-nowrap`}>
              &nbsp;&#8226;&nbsp;Meet the Artists, Engineers, Designers and Scientists Who Know What Tomorrow Looks Like
            </p>
          )}
        </Ticker>
      </div>
      <p className={`${textColor} ${constants.quote} ${overallPadding} px-7 pr-10 pt-16 pb-6 lg:pt-20 lg:pb-10`}>
        The Brand Collection, together with the Core Collection, forms our main line. Hands-free styles such as the Backpack, Phone and Camera Bags fuse high-functionality with soft, comforting leathers and simplicity of design. These gender-free pieces are soft and casual — the ideal companions for modern life on the move.
      </p>
      <Grid className={`${overallPadding} mt-10 lg:mt-10`}>
        <div className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-6 mb-16 lg:mb-0`}>
          <VideoPlayer
            url="210125_SHB_1.mp4"
            thumbnail={data.thumb}
            className=""
          />
          <p className={`pl-2 lg:pl-0 ${constants.caption} pt-2`}>
            Meet the designer and discover the experiment.
          </p>
        </div>
        <div className={`col-start-2 col-end-11 lg:col-start-6 lg:col-end-13 mb-5 lg:mb-0`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
            <div className={`lg:mx-auto w-full lg:w-7/12 ${overallPadding}`}>
              <p className={`${textColor} ${constants.paragraph} pb-4`}>Inspired by Food Experimenters</p>
              <p className={`${textColor} ${constants.paragraph}`}>
                Together with Studio Human Beings we created a design brief for a food project that explores experimental bread making. We then asked people from our network to conduct the experiment, using ECCO Leather Goods as their essential equipment, or work goods. Our lookbook images capture the spirit of designers in progress.
              </p>
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-1 col-end-12 lg:col-start-1 lg:col-end-13`}
          captionClassName={`${textColor} pl-2 lg:pl-0`}
          image={data.cristiani2}
        >
          Image 1
        </MediaWithCaption>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <div className={`col-start-2 col-end-11 lg:col-start-1 lg:col-end-6 pb-7 lg:pb-0`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
            <div className={`lg:mx-auto w-full lg:w-7/12 ${overallPadding}`}>
              <p className={`${textColor} ${constants.paragraph}`}>Hands-free for greater comfort and functionality.</p>
            </div>
          </div>
        </div>
        <MediaWithCaption
          className={`${overallPadding} col-start-2 col-end-13 lg:col-start-6 lg:col-end-13`}
          captionClassName={`${textColor}`}
          image={data.cristiani3}
        >
          Image 2
        </MediaWithCaption>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-1 col-end-12 lg:col-start-3 lg:col-end-9`}
          captionClassName={`${textColor} pl-2 lg:pl-0`}
          image={data.cristiani4}
        >
          Image 3
        </MediaWithCaption>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <div className={`col-start-2 col-end-11 lg:col-start-3 lg:col-end-8 pb-7 lg:pb-0`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
            <div className={`lg:mx-auto w-full lg:w-7/12 ${overallPadding}`}>
              <p className={`${textColor} ${constants.paragraph}`}>Segolene creates the perfect mix of small leather goods.</p>
            </div>
          </div>
        </div>
        <div className={`${overallPadding} col-start-2 col-end-13 lg:col-start-8 lg:col-end-13`}>
          <LazyLoad>
            <InlineVideo
              src="220121_CONVOY_ECCO_ROCKA_ETALO_V2_H264.mp4"
              videoStyle={{objectFit: 'cover'}}
            />
          </LazyLoad>
        </div>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <MediaWithCaption
          className={`col-start-1 col-end-13 lg:col-start-1 lg:col-end-13`}
          captionClassName={`${textColor} pl-2 lg:pl-0`}
          image={data.cristiani5}
        />
      </Grid>
      <Grid className={`mt-10 lg:mt-32 ${overallPadding}`}>
        <div className={`col-start-2 col-end-13 lg:col-start-1 lg:col-end-7 mb-7 lg:mb-0`}>
          <Img
            fluid={data.cristiani7.childImageSharp.fluid}
            className={`w-full h-full`}
          />
        </div>
        <div className={`col-start-1 col-end-12 lg:col-start-7 lg:col-end-13`}>
          <Img
            fluid={data.cristiani8.childImageSharp.fluid}
            className={`w-full h-full`}
          />
        </div>
        <p className={`pl-2 lg:pl-0 ${constants.caption} pt-2 col-start-1 col-end-13`}>
          Image 4
        </p>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <div className={`${overallPadding} col-start-1 col-end-12 lg:col-start-3 lg:col-end-8`}>
          <LazyLoad>
            <InlineVideo
              src="210121_CONVOY_ECCO_BREADB_ETALO_V2_H264.mp4"
              videoStyle={{objectFit: 'cover'}}
            />
          </LazyLoad>
          <p className={`pl-2 lg:pl-0 ${constants.caption} pt-2 col-start-1 col-end-13`}>
            Image 5
          </p>
        </div>
        <div className={`col-start-2 col-end-11 lg:col-start-8 lg:col-end-13`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full pt-7 lg:pt-0`}>
            <div className={`lg:mx-auto w-full lg:w-7/12 ${overallPadding}`}>
              <p className={`${textColor} ${constants.paragraph}`}>Djeneba formulates a custom mix of spirulina, turmeric and beet for her experimental dough.</p>
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-2 col-end-13 lg:col-start-1 lg:col-end-13`}
          captionClassName={`${textColor}`}
          image={data.cristiani9}
        >
          Image 6
        </MediaWithCaption>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-1 col-end-12 lg:col-start-1 lg:col-end-6 mb-7 lg:mb-0`}
          captionClassName={`pl-2 lg:pl-0 ${textColor}`}
          image={data.cristiani10}
        >
          Image 7
        </MediaWithCaption>
        <MediaWithCaption
          className={`${overallPadding} col-start-2 col-end-13 lg:col-start-7 lg:col-end-13 lg:pt-48`}
          captionClassName={`${textColor}`}
          image={data.cristiani11}
        >
          Image 8
        </MediaWithCaption>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <MediaWithCaption
          className={`${overallPadding} col-start-1 col-end-12 lg:col-start-3 lg:col-end-8`}
          captionClassName={`${textColor} pl-2 lg:pl-0`}
          image={data.cristiani12}
        >
          Image 9
        </MediaWithCaption>
        <div className={`col-start-2 col-end-11 lg:col-start-8 lg:col-end-13 pt-7 lg:pt-0`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
            <div className={`lg:mx-auto w-full lg:w-7/12 ${overallPadding}`}>
              <p className={`${textColor} ${constants.paragraph}`}>Designed to maximize functionality with the right amount of volume for daily necessities.</p>
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={`mt-10 lg:mt-32 ${overallPadding}`}>
        <div className={`col-start-2 col-end-13 lg:col-start-1 lg:col-end-7 mb-7 lg:mb-0`}>
          <Img
            fluid={data.cristiani13.childImageSharp.fluid}
            className={`w-full h-full`}
          />
        </div>
        <div className={`col-start-1 col-end-12 lg:col-start-7 lg:col-end-13`}>
          <Img
            fluid={data.cristiani14.childImageSharp.fluid}
            className={`w-full h-full`}
          />
        </div>
        <p className={`pl-2 lg:pl-0 ${constants.caption} pt-2 col-start-1 col-end-13`}>
          Image 10
        </p>
      </Grid>
      <Grid className={`mt-10 lg:mt-32`}>
        <div className={`col-start-2 col-end-11 lg:col-start-1 lg:col-end-6 mb-5 lg:mb-0`}>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:h-full`}>
            <div className={`lg:mx-auto w-full lg:w-8/12 ${overallPadding}`}>
              <p className={`${textColor} ${constants.paragraph}`}>
                Mix and match small leather goods to create your ultimate custom companion.
              </p>
            </div>
          </div>
        </div>
        <MediaWithCaption
          className={`${overallPadding} col-start-2 col-end-13 lg:col-start-6 lg:col-end-13`}
          captionClassName={`pr-2 lg:pr-0 ${textColor}`}
          image={data.cristiani15}
        >
          Image 11
        </MediaWithCaption>
      </Grid>
      <IndexList
        className={`${textColor} ${overallPadding} ${constants.attribution} mt-10 lg:mt-24`}
        items={indexItems}
      />
      <Diptyque
        props={{
          title: `Enter a Brave New World`,
          paragraph: `Mathias Holst is featured in the AW/21 campaign for our Brand Collection. He is wearing the Pot Bag in Marigold and Aubergine.`,
          color: `#EF3413`,
          borderColor: `border-black`,
          textColor: `text-black`,
          image: data.diptyque,
          button: {
            to: `/catalogue?collection=brand`,
            text: `Discover the AW/21 campaign`
          }
        }}
      />
      <SendToPageCTA
        title={`Discover the Core Collection`}
        button={{
          to: `/catalogue`,
          title: `View All Products`
        }}
      />
    </div>
  )
}

export default Brand
