import React, { useState, useRef, useEffect } from "react"
import Img from "gatsby-image"
import constants from "../../constants"
import { motion, AnimatePresence } from 'framer-motion'
import useDeviceVersion from "../../hooks/use-device-version"

const VideoPlayer = ({ url, thumbnail, thumbnailMobile, className, videoClassName, basepath }) => {
  let base = basepath ? basepath : constants.S3
  const [isPlaying, setIsPlaying] = useState(false)
  const [isHidden, setIsHidden] = useState(true);
  const video = useRef()
  const thumbImg = useRef()
  const container = useRef()
  const parent = useRef()
  const deviceVersion = useDeviceVersion();
  let version = deviceVersion;
  if (version !== undefined) {
    version = version + '/';
  }
  const onClick = () => {
    setIsPlaying(!isPlaying)
  }
  useEffect(() => {
    if (version !== undefined) {
      setIsHidden(false)
    }
  }, [version])
  useEffect(() => {
    video.current?.addEventListener(`ended`, onVideoFinished)
    return (() => {
      video.current?.removeEventListener(`ended`, onVideoFinished)
    })
  }, [isPlaying])
  const onVideoFinished = () => {
    setIsPlaying(false)
  }
  const thumbImage = () => {
    if (deviceVersion === 'mobile' && thumbnailMobile) {
      return (
        <Img
          ref={thumbImg}
          className={`w-full h-full`}
          imgStyle={{objectFit: 'cover'}}
          fluid={thumbnailMobile?.childImageSharp?.fluid}
        />
      )
    } else {
      return (
        <Img
          ref={thumbImg}
          className={`w-full h-full`}
          imgStyle={{objectFit: 'cover'}}
          fluid={thumbnail?.childImageSharp?.fluid}
        />
      )
    }
  }
  return (
    <div ref={parent} className={`${className} relative w-full h-full overflow-hidden`}>
      { !isHidden && (
        <>
          <button ref={container} onClick={onClick} className={`relative z-10 top-0 left-0 w-full h-full ${isPlaying ? `pointer-events-none` : `pointer-events-auto`}`}>
            <div className={`relative w-full h-full duration-300 ${isPlaying ? `opacity-0` : `opacity-100`}`}>
              <div className={`absolute z-20 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
                <svg className={`w-16`} viewBox="0 0 33 39" fill="none">
                  <path d="M33 19.4395L0.749998 38.059L0.75 0.819905L33 19.4395Z" fill="white"/>
                </svg>
              </div>
              <div className={`relative z-10 top-0 left-0 w-full h-full`}>
                { thumbImage() }
              </div>
            </div>
          </button>
          <div className="absolute top-0 w-full h-full">
            <AnimatePresence>
              { (isPlaying) &&
              <motion.div
                className={`w-full h-full`}
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                <video
                  playsInline
                  autoPlay
                  ref={video}
                  controls
                  className={`${videoClassName} w-full h-full`}
                  style={{objectFit: version === 'mobile' ? 'contain' : 'cover'}}
                >
                  <source src={`${base}${version}${url}`} type={`video/mp4`} />
                </video>
              </motion.div>
              }
            </AnimatePresence>
          </div>
        </>
      )}
    </div>
  )
}

export default VideoPlayer
