import React from "react"
import constants from "../../constants"

const IndexList = ({ className, items, pushToLeft = true, cols }) => {
  return (
    <>
      <div className={`hidden lg:grid lg:grid-cols-5 py-20 ${className}`}>
        <div className={`${constants.heading}`}>—Index</div>
        {pushToLeft && <div className={``}></div>}
        {
          items.map((ul, a) => {
            return (
              <div key={`u-list-${a}`} className={`px-0 lg:px-6`}>
                <ul className={`lg:grid lg:gap-y-6`}>
                  {
                    ul.map((li, b) => {
                      return <li key={`list-${a}-${b}`} dangerouslySetInnerHTML={{__html: li}} />
                    })
                  }
                </ul>
              </div>
            )
          })
        }
      </div>
      <div className={`lg:hidden py-10 pb-14 px-6 ${className}`}>
        <div className={`${constants.heading} pb-4`}>—Index</div>
        <div className={`grid grid-cols-3`}>
          {
            items.map((ul, a) => {
              return (
                <div key={`u-list-${a}`} className={`pt-2 px-5 lg:px-6`}>
                  <ul className={`grid gap-y-6`}>
                    {
                      ul.map((li, b) => {
                        return <li key={`list-${a}-${b}`} dangerouslySetInnerHTML={{__html: li}} />
                      })
                    }
                  </ul>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default IndexList
